<template>
  <LeftSection>
    <div class="info-desc col-lg-6 mt-5">
      <p class="desc text-start">
        Valimail, the first of its kind, an evolving Identity-based,
        anti-fraudulent company that has been successful in ensuring the
        trustworthiness of its clients in terms of digital transmission of data
        around the globe since 2015. Valimail delivers a safe, cloud-native
        platform and authorizes the senders’ data and identity to check whether
        or <br />
        not there is a harmful entity that may damage or exploit you, your data,
        or your privacy in any way. Valimail ensures their clients or users to
        show submissiveness and agreement to its rules and regulations to
        protect your brand and help it grow stronger. Valimail offers its
        services and authenticates large sums of data for some of the biggest
        and eminent organizations of the world including Uber, Splunk, Yelp,
        Fannie Mae, Mercedes Benz USA, and the U.S, and has won more than a
        dozen reputable Cyber Security Technology awards which shows that the
        company has been more than successful so far.
      </p>
      <p class="desc">For more information visit www.valimail.com.</p>
    </div>
    <div class="info-img col-lg-6 mt-5">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/valimail-defend/data-sheet_-valimail-defend-3_07.png"
        alt="HOW valmail WORKS"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "sixth-section",
};
</script>
