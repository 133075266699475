<template>
  <LeftSection>
    <div class="info-desc col-lg-8 mt-5">
      <h2 class="title-green h1">Key Characteristics</h2>
      <p class="desc">
        A single-click integration into your Microsoft office-365 and Google
        provides shielding intuition of your company, Including the volume of
        penetrating threats and warning from unverified domains and connections.
        If an emailing domain is not stored in your trusted contacts or domain,
        it will be marked as an untrusted domain.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100 mt-4"
        src="../../../../assets/cyber-security/valimail-defend/valimail-defend-ejaf-technology.png"
        alt="Key Characteristics"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "third-section",
};
</script>
