<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Shield Your Most Striked People</h2>
      <p class="desc">
        Through Cybersecurity solution(s), you can easily see the threats or
        warnings that target the workers at risk. For instance, the head, the
        department that determines the amount of wage due to each worker or
        finance department. Defend also gives the manager a skill to recruit a
        group of workers or single mail slots to watch lists, for uncomplicated
        entry to shielding insights and strategy to keep them protected and
        secure.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/valimail-defend/valimail-defend-ejaf-technology-canada.png"
        alt="Shield Your Most Striked People"
      />
    </div>
    <div class="benifits mt-5">
      <h2 class="text-center my-5">KEY BENEFITS</h2>
      <key-benifits />
    </div>
    <div class="works text-center mt-5">
      <h2 class="mt-5">HOW IT WORKS</h2>
      <p class="desc">The World’s Largest Repository of Trusted Domains</p>
      <img
        class="w-100 text-center"
        src="../../../../assets/cyber-security/valimail-defend/data-sheet_-valimail-defend.png"
        alt="HOW valmail WORKS"
      />
    </div>
  </LeftSection>
</template>

<script>
import KeyBenifits from "./KeyBenifits.vue";
export default {
  name: "fivth-section",
  components: { KeyBenifits },
};
</script>
