<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">DEFEND YOUR INBOX AGAINST PHISH</h2>
      <h4>Keep your inbox safe from hacking emails</h4>
      <p class="desc">
        Hacking email has many different types, including gift voucher trick,
        wire transfer scam, qualification or identification robbery, W2 theft
        and so on. They pretend to be someone else and use the impersonation
        technique to attack. It can also be termed a fake identity, making it
        difficult for email security to track. They find it hard to monitor
        because of ever-changing tricks. As the Cybersecurity solutions secure,
        mail requires observing, arrangement and conservation.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/valimail-defend/valimail3.png"
        alt="DEFEND YOUR INBOX AGAINST PHISH"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-section",
};
</script>
