<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Card Readers</h2>
      <p class="desc">
        Accessible with industry-standard Wiegand and clock/information yield,
        are perfect with numerous OEM get to control frameworks. Peruse low
        vitality (charge card) or high vitality attractive stripe cards. The
        per-user interprets card data to choose Wiegand arranges as required by
        the OEM’s controller.
      </p>
      <p class="desc">
        Valimail’s defender uses zero trust email security approach for absolute
        safety by quickly verifying each sender that sends your email and cross
        your secure email gateway in opposition to our data archive of more than
        30 million Trustworthy .worthy domains. Furthermore, it instinctively
        contemporizes your concern contact list to our most trust-worthy shipper
        database. Once you are all done setting up the strategy, defend
        instinctively react on your all messages based on the procedure or plan
        and give shielding to all of your workers and by putting an end to all
        the incorrect positives. If the person who sends you mail is trusted,
        then the mail stays in worker inbox. But if the sender comes out to be
        not verified, the mail is indicated and moved to the group strategy.
        Cybersecurity solutions are that straight forward.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100 mt-4"
        src="../../../../assets/cyber-security/valimail-defend/valimail1.png"
        alt="Card Readers"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-section",
};
</script>
