<template>
  <valmail-defend />
</template>

<script>
import ValmailDefend from "../../components/solutions/cyber-security/ValmailDefend.vue";
export default {
  components: { ValmailDefend },
  name: "valmail-defend-page",
};
</script>
