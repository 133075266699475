<template>
  <ul class="row info-desc">
    <li class="col-lg-4 heading">
      <h4>Stop Hacking Attacks</h4>
      <ul class="row">
        <li class="col-12">
          <p>
            It provides shield to the workers from business email compromise
            (BEC) hacking and hostile email threats.
          </p>
        </li>
        <li class="col-12">
          <p>
            It completely penetrates the emails from unverified domains and
            directly unfastens the sign-up system, for instance, Gmail and
            yahoo.
          </p>
        </li>
      </ul>
    </li>
    <li class="col-lg-4 heading">
      <h4>One-click Combination</h4>
      <ul class="row">
        <li class="col-12">
          <p>Consistent office365 and G-Suite link</p>
        </li>
        <li class="col-12">
          <p>It doesn't need any software or hardware to install</p>
        </li>
        <li class="col-12">
          <p>
            You will get protected in no time, which will also save your time
          </p>
        </li>
      </ul>
    </li>
    <li class="col-lg-4 heading">
      <h4>No-trust, Identification-based inbox shielding</h4>
      <ul class="row">
        <li class="col-12">
          <p>
            It only gives access to mail delivery from trustworthy and verified
            domains and contacts
          </p>
        </li>
        <li class="col-12">
          <p>
            Contemporize the company's catalog of trusted "open sign up"
            communication
          </p>
        </li>
      </ul>
    </li>
    <li class="mt-4 col-lg-4 heading">
      <h4>Straight Forward Strategy Arrangement</h4>
      <ul class="row">
        <li class="col-12">
          <p>
            Granular command based upon part and danger appetite for every
            company.controls based on roles and risk appetite for each
            organization
          </p>
        </li>
        <li class="col-12">
          <p>
            It also has automated features of updated for unverified as well as
            trusted domain computing
          </p>
        </li>
      </ul>
    </li>
    <li class="mt-4 col-lg-4 heading">
      <h4>Grabs what existing solutions miss</h4>
      <ul class="row">
        <li class="col-12">
          <p>
            Gives standalone shield with fundamental and built-in content
            filters in 0365 and G suite. It can also be merged with existing
            security gadgets for layered protection
          </p>
        </li>
        <li class="col-12">
          <p>Accompaniment all famous SEGs</p>
        </li>
      </ul>
    </li>
    <li class="mt-4 col-lg-4 heading">
      <h4>Comprehensive Reporting</h4>
      <ul class="row">
        <li class="col-12">
          <p>Unverified sender volume, most highlighted inbox, and many more</p>
        </li>
        <li class="col-12">
          <p>Daily, as well as monthly volume tendency, envision</p>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "key-benifits",
};
</script>
