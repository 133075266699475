<template>
  <RightSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Guideline Control at Your Fingerprint</h2>
      <p class="desc">
        Protection provides entry to a large diversity of guideline control and
        arrangement on how to deal with unverified email traffic. The manager
        can allocate plans for the watch list, single mail slot, and traffic
        from the domain by following the steps.
      </p>
      <ul>
        <li>
          <p>Transfer to spam</p>
        </li>
        <li>
          <p>Transfer to trash</p>
        </li>
        <li>
          <p>Transfer to custom file</p>
        </li>
        <li>
          <p>Attach label</p>
        </li>
        <li>
          <p>Scan only</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/cyber-security/valimail-defend/valimail.png"
        alt="Guideline Control at Your Fingerprint"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-section",
};
</script>
